<script setup>

</script>

<template>
  <footer class="footer">
    <div class="footer-contain">
      <router-link to="/" class="footer-menu">
        <i class="pi pi-home"></i>
        <p>Inicio</p>
      </router-link>
      <router-link to="/points-details" class="footer-menu">
        <i class="pi pi-history"></i>

        <p>Historial</p>
      </router-link>
<!--      <router-link  to="/ranking" class="footer-menu">
        <i class="fa-solid fa-ranking-star"></i>
        <p>Ranking</p>
      </router-link>-->
      <router-link to="/factura" class="footer-menu">
        <i class="pi pi-file-plus"></i>
        <p>Factura</p>
      </router-link>
      <a class="footer-menu" href="https://wa.me/+573505529223" target="_blank">
        <i class="pi pi-comment"></i>
        <p>Soporte</p>
      </a>
    </div>

  </footer>
</template>

<style scoped>

</style>
