<script setup>

import {inject} from "vue";

const rootScope = inject('rootScope');
</script>

<template>
  <header class="hidden-xs">

      <div class="header-contain">
        <router-link :to=" rootScope.logged ? '/home' : '/'" >
          <picture class="header-logo">
            <img src="https://images-development-codi.s3.us-east-1.amazonaws.com/postobon-frisby/OCR-Postobon/hit-logo-header.webp" alt="logo">
          </picture>
        </router-link>
        
    </div>
  </header>
</template>

<style scoped>

</style>
