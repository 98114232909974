import {createApp, reactive} from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'

import '@/assets/css/styles.css'

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import PrimeVue from 'primevue/config';
import AutoComplete from 'primevue/autocomplete';
import Paginator from "primevue/paginator";
import ProgressSpinner from 'primevue/progressspinner';
import i18n from "@/utils/i18n";

const app = createApp(App);

app.component('AutoComplete', AutoComplete);
app.component('Paginator', Paginator)
app.component('ProgressSpinner', ProgressSpinner)

app.use(router);

const options = {
    confirmButtonColor: '#41b882',
    cancelButtonColor: '#ff7674',
};
app.use(VueSweetalert2, options);


// providers
const state = reactive({
    loading: false,
    logged: false,
    name: '',
    user: 0,
    tipo_cliente: '',
    saldo_creditos: 0,
    saldo_acumulado: 0,
    registro_completo: 0,
    producto: {
        descripcion : '',
        credito: 0,
        productoId: 0,
        tipoCliente: 0,
        tipoRedencion: 0,
        totalRedimidos: 0
    }
})
app.use(i18n);
app.use(PrimeVue, {ripple: true});

app.provide(/* key */ 'rootScope', /* value */ state);

app.mount('#app');

