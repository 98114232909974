import { createI18n } from 'vue-i18n';

// Definir los mensajes de error en diferentes idiomas
const messages = {
    en: {
        validations: {
            required: "The field {property} is required.",
            minLength: "The {property} field has a value of '{model}', but it must have a min length of {min}.",
            maxLength: "The {property} field has a value of '{model}', but it must have a max length of {max}.",
            email: "The {property} field must be a valid email address."
        }
    },
    es: {
        validations: {
            required: "Este campo es obligatorio.",
            minLength: "El campo {property}, debe tener una longitud mínima de {min}.",
            maxLength: "El campo {property}, debe tener una longitud máxima de {max}.",
            email: "El campo {property} debe ser una dirección de correo válida."
        }
    }
};

const i18n = createI18n({
    locale: 'es', // Idioma por defecto
    fallbackLocale: 'es',
    messages
});

export default i18n;
